                                        .home {
                                            background: #fff;
                                            width: 100%;
                                            height: 100%;
                                            overflow: hidden;
                                            overflow-y: auto;
                                            -webkit-overflow-scrolling: touch;
                                            .home-header {
                                                background: url(../../assets/image/home/bg.png);
                                                background-size: 100% 200px;
                                                background-repeat: no-repeat;
                                                padding-top: 15px;
                                                position: relative;
                                                .icon-home {
                                                    width: 115px;
                                                    height: 113px;
                                                    background: url(../../assets/image/home/icon-home.png) no-repeat center;
                                                    background-size: cover;
                                                    position: absolute;
                                                    right: 10px;
                                                    top: 68px;
                                                }
                                                .head-search {
                                                    height: 30px;
                                                    border-radius: 15px;
                                                    opacity: 0.33;
                                                    border: 1px solid #D45717;
                                                    margin: 0 20px 30px 20px;
                                                    display: flex;
                                                    justify-content: flex-start;
                                                    align-items: center;
                                                    opacity: 0.33;
                                                    .icon-search {
                                                        width: 14px;
                                                        height: 14px;
                                                        margin: 0 6px 0 16px;
                                                    }
                                                    span {
                                                        font-size: 13px;
                                                        color: #D45717;
                                                        line-height: 30px;
                                                    }
                                                }
                                                .head-welcome {
                                                    padding: 0 0 25px 20px;
                                                    .tit {
                                                        font-size: 22px;
                                                        font-weight: 600;
                                                        color: #D45717;
                                                        line-height: 30px;
                                                        padding-bottom: 6px;
                                                    }
                                                    .txt {
                                                        font-size: 14px;
                                                        color: #D45717;
                                                        line-height: 20px;
                                                    }
                                                }
                                                .my-swipe {
                                                    height: 120px;
                                                    background: #FFFFFF;
                                                    box-shadow: 0px 2px 8px 4px rgba(255, 222, 189, 0.2);
                                                    border-radius: 5px;
                                                    margin: 0 20px;
                                                    /deep/ .van-swipe__track {
                                                        width: 100% !important;
                                                    }
                                                    /deep/.van-swipe-item {
                                                        width: 100% !important;
                                                        height: 100%;
                                                        display: flex;
                                                        justify-content: flex-start;
                                                        align-items: center;
                                                        padding: 0 12px 12px 12px;
                                                        position: relative;
                                                        .icon-tuijian {
                                                            width: 29px;
                                                            height: 22px;
                                                            position: absolute;
                                                            top: 0;
                                                            left: 0;
                                                        }
                                                        .item-lt {
                                                            width: 120px;
                                                            height: 74px;
                                                            background: url(../../assets/image/home/bg-zw.png) center/100%, 100% no-repeat;
                                                            border-radius: 5px;
                                                            overflow: hidden;
                                                            .img {
                                                                width: 100%;
                                                                height: 100%;
                                                                border-radius: 5px;
                                                            }
                                                        }
                                                        .item-rt {
                                                            display: flex;
                                                            justify-content: space-between;
                                                            flex-direction: column;
                                                            height: 100%;
                                                            flex: 1;
                                                            padding: 12px 0 12px 10px;
                                                            .title {
                                                                text-align: start;
                                                            }
                                                            .rt-bt {
                                                                display: flex;
                                                                justify-content: space-between;
                                                                align-items: center;
                                                                width: 100%;
                                                                .txt {
                                                                    font-size: 13px;
                                                                    color: #999999;
                                                                    line-height: 18px;
                                                                }
                                                                .spike {
                                                                    display: inline-block;
                                                                    margin-left: 4px;
                                                                    background: url(https://file.huatu.com/static/miniprogram/shop/h5/spike/icon-courselist-spike@2x.png) no-repeat 0 0;
                                                                    background-size: cover;
                                                                    width: 34px;
                                                                    height: 16px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    /deep/.van-swipe__indicator {
                                                        background-color: #FFD8BE;
                                                    }
                                                    /deep/.van-swipe__indicators {
                                                        bottom: 8px;
                                                    }
                                                }
                                            }
                                            .tab-type {
                                                .type {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;
                                                    padding: 20px 0px 5px 0px;
                                                    button {
                                                        margin: 0px;
                                                        padding: 0px;
                                                        border: 0px;
                                                        outline: none;
                                                        background: transparent;
                                                    }
                                                    .tit {
                                                        font-size: 16px;
                                                        font-weight: 600;
                                                        color: #333333;
                                                        line-height: 22px;
                                                        // padding: 10px;
                                                        text-align: center;
                                                        width: 70px;
                                                        // width: 32px;
                                                        // height: 22px;
                                                        // font-family: PingFangSC, PingFang SC;
                                                        // font-weight: 500;
                                                        // font-size: 16px;
                                                        // color: #333333;
                                                        // line-height: 22px;
                                                        // text-align: left;
                                                        // font-style: normal
                                                    }
                                                    .active {
                                                        // font-size: 18px;
                                                        // font-weight: 600;
                                                        // color: #333333;
                                                        // line-height: 25px;
                                                        // position: relative;
                                                        // width: 32px;
                                                        // width: 92px;
                                                        // height: 22px;
                                                        font-family: PingFangSC, PingFang SC;
                                                        font-weight: 500;
                                                        font-size: 16px;
                                                        color: #FE5C32;
                                                        // line-height: 22px;
                                                        text-align: center;
                                                        font-style: normal
                                                    }
                                                    .active::before {
                                                        content: "";
                                                        position: absolute;
                                                        left: 50%;
                                                        bottom: 0;
                                                        width: 24px;
                                                        height: 9px;
                                                        margin-left: -12px;
                                                        background: url(../../assets/image/home/selecet-icon.png);
                                                        background-size: 24px 9px;
                                                        background-repeat: no-repeat;
                                                    }
                                                    /deep/.van-tabs__nav--complete {
                                                        padding-right: 0;
                                                        padding-left: 0;
                                                    }
                                                }
                                                .items {
                                                    overflow-x: scroll;
                                                    overflow-y: hidden;
                                                    position: relative;
                                                    padding-right: 48px;
                                                    height: 40px;
                                                    .icon-more {
                                                        width: 33px;
                                                        height: 26px;
                                                        position: absolute;
                                                        right: 15px;
                                                        top: 0;
                                                    }
                                                    .item {
                                                        display: flex;
                                                        justify-content: space-between;
                                                        align-items: flex-start;
                                                        padding: 0 11px;
                                                        overflow-x: scroll;
                                                        overflow-y: hidden;
                                                        white-space: nowrap;
                                                        /* 父级元素中的内容不换行 */
                                                        height: 50px;
                                                        .tit {
                                                            min-width: 74px;
                                                            line-height: 26px;
                                                            background: #F6F6F6;
                                                            border-radius: 17px;
                                                            text-align: center;
                                                            color: #999999;
                                                            margin-right: 11px;
                                                            font-size: 13px;
                                                        }
                                                        .active {
                                                            background: #FFEFE6;
                                                            color: #FC6C21;
                                                        }
                                                    }
                                                }
                                                .mianshou {
                                                    .filter {
                                                        .item {
                                                            padding-bottom: 10px;
                                                            padding-left: 15px;
                                                            padding-top: 14px;
                                                            .item-tit {
                                                                font-size: 14px;
                                                                color: #333333;
                                                                line-height: 20px;
                                                                padding-bottom: 12px;
                                                                font-weight: 500;
                                                            }
                                                            .item-box {
                                                                display: flex;
                                                                justify-content: flex-start;
                                                                align-items: center;
                                                                flex-wrap: wrap;
                                                                .item-txt {
                                                                    width: 70px;
                                                                    font-size: 13px;
                                                                    color: #666666;
                                                                    line-height: 18px;
                                                                    padding: 5px 9px;
                                                                    background: rgba(0, 0, 0, 0.04);
                                                                    border-radius: 14px;
                                                                    margin-right: 20px;
                                                                    margin-bottom: 16px;
                                                                    text-align: center;
                                                                }
                                                                .active {
                                                                    background: #FFEFE6;
                                                                    color: #FC6C21;
                                                                }
                                                            }
                                                            .item-txt:nth-child(4n+4) {
                                                                margin-right: 0;
                                                            }
                                                        }
                                                        .item-btn {
                                                            font-size: 16px;
                                                            line-height: 46px;
                                                            text-align: center;
                                                            display: flex;
                                                            justify-content: flex-start;
                                                            .reset {
                                                                width: 143px;
                                                                background: #EFEFEF;
                                                                color: #262626;
                                                            }
                                                            .search {
                                                                flex: 1;
                                                                color: #ffffff;
                                                                background: linear-gradient(137deg, #FF9D5B 0%, #FC6C21 100%);
                                                                box-shadow: 0px 0px 9px 0px rgba(96, 105, 142, 0.06);
                                                            }
                                                        }
                                                    }
                                                }
                                                .mianshou /deep/ .van-dropdown-menu__bar {
                                                    box-shadow: unset !important;
                                                }
                                                .mianshou /deep/ .van-dropdown-menu__title {
                                                    color: #666666;
                                                }
                                                .mianshou /deep/ .van-dropdown-menu__bar {
                                                    height: 30px !important;
                                                }
                                            }
                                            .kech-page {
                                                padding: 0 15px;
                                                background: #ffffff;
                                                .item {
                                                    display: flex;
                                                    justify-content: flex-start;
                                                    align-items: center;
                                                    padding: 15px 0;
                                                    border-bottom: 1px solid #F0F0F0;
                                                    .item-lt {
                                                        width: 120px;
                                                        height: 74px;
                                                        .lt-img {
                                                            width: 100%;
                                                            height: 100%;
                                                            border-radius: 5px;
                                                        }
                                                    }
                                                    .item-rt {
                                                        display: flex;
                                                        justify-content: space-between;
                                                        align-items: flex-start;
                                                        flex-direction: column;
                                                        padding-left: 12px;
                                                        height: 74px;
                                                        flex: 1;
                                                        .title {
                                                            text-align: start;
                                                            .type {
                                                                width: 36px;
                                                                display: inline-block;
                                                                background: linear-gradient(120deg, #FE7000 0%, #FC4607 100%);
                                                                border-radius: 0px 3px 3px 3px;
                                                                opacity: 0.79;
                                                                color: #ffffff;
                                                                line-height: 17px;
                                                                text-align: center;
                                                                font-size: 10px;
                                                            }
                                                        }
                                                        .rt-bt {
                                                            width: 100%;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: space-between;
                                                            .bt-lt {
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: flex-start;
                                                                .status {
                                                                    width: 34px;
                                                                    height: 16px;
                                                                    background: linear-gradient(231deg, #FE2F00 0%, #FF53B3 100%);
                                                                    border-radius: 0px 4px 4px 4px;
                                                                    color: #ffffff;
                                                                    margin-left: 4px;
                                                                    line-height: 16px;
                                                                    text-align: center;
                                                                    font-size: 10px;
                                                                }
                                                            }
                                                            .bt-rt {
                                                                font-size: 13px;
                                                                color: #999999;
                                                                line-height: 18px;
                                                            }
                                                        }
                                                    }
                                                }
                                                .item:last-child {
                                                    border: nonw;
                                                }
                                            }
                                        }